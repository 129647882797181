.skill-section {
  padding: 10rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 5rem;
    margin-bottom: 10rem;
  }

  .skills {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .skill {
      display: grid;
      place-items: center;
      border: 1px solid none;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #23d997;
      padding: 1rem 2rem;
      margin: 2rem;
      z-index: 10;

      img {
        transition: all 0.3s ease;
        margin-bottom: 1rem;

        &:hover {
          transform: scale(1.2) rotate(-360deg);
        }
      }

      h2 {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .skill-section h1 {
    font-size: 3.5rem;
  }
}
