.about-section {
  min-height: 90vh;
  padding: 5rem 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .description {
    width: 50%;
    margin-right: 5rem;
    display: flex;
    flex-direction: column;
    z-index: 2;
    transition: all 0.3s ease;

    .title {
      font-size: 4rem;
      font-family: "Josefin Sans", sans-serif;
      overflow: hidden;

      .hide > h1 {
        margin-bottom: 3rem;
      }

      .title-first {
        animation: firstTitle 1s ease;
      }

      .title-second {
        animation: secondTitle 2s ease;
      }
    }

    .sub-title {
      margin: 2rem 0;
      font-size: 2.8rem;
      animation: subTitle 1.3s ease;

      h2 {
        font-weight: 400;
      }
      span {
        color: #23d997;
        font-weight: 700;
      }
    }

    p {
      margin: 2rem 0 3rem 0;
      width: 80%;
      font-size: 2.1rem;
      line-height: 150%;
      opacity: 0.6;
    }
  }

  .image {
    position: relative;
    border-radius: 30% 70% 40% 60% / 30% 30% 70% 70%;
    box-shadow: -10px 55px 0px 20px #1b1b1b, 10px 50px 25px 0px #23d997;
    overflow: hidden;
    transition: transform 0.3s ease-in;
    cursor: pointer;
    animation: img-animation 1s ease-in-out;
    z-index: 10;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.2;
      z-index: 1;
    }

    img {
      width: 100%;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}

@keyframes img-animation {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes firstTitle {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes secondTitle {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes subTitle {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// responsive

@media screen and (max-width: 1500px) {
  .about-section .description {
    .title {
      font-size: 3.5rem;
    }
  }
}

@media screen and (max-width: 1300px) {
  .about-section {
    padding: 5rem;
    .description {
      .title {
        font-size: 3rem;
      }
      .sub-title {
        font-size: 2.2rem;
      }
      p {
        font-size: 1.8rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about-section {
    flex-direction: column;

    .description {
      width: 100%;
      align-items: center;
      margin-right: 0;
      margin-bottom: 5rem;
      text-align: center;

      p {
        width: 60%;
        margin-bottom: 5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-section {
    padding: 5rem 2rem;

    .description {
      p {
        width: 100%;
      }
    }

    .image {
      margin: 0 2rem 0 1rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .about-section {
    .description {
      .title {
        font-size: 2.5rem;
      }

      .sub-title {
        font-size: 2rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }
}
