.projects-section {
  width: 100%;

  .heading {
    text-align: center;
    margin: 5rem 0;
    font-size: 3.5rem;
  }

  .projects {
    margin: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .project {
      width: 60%;
      margin: 4rem 0;
      padding: 4rem;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.5) inset;

      h2 {
        font-size: 3rem;
        text-align: center;
        margin: 3rem 0 1rem 0;
      }

      p {
        font-size: 2rem;
        opacity: 0.5;
        margin: 2rem 0;
        text-align: center;
        line-height: 140%;
      }

      .project-links {
        display: flex;
        justify-content: center;

        a {
          font-size: 1.6rem;
          color: white;
          margin: 0 1rem;
          padding: 1rem 2rem;
          border: 1px solid #23d997;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            background-color: #23d997;
            color: black;
          }
        }
      }

      img {
        width: 100%;
        filter: drop-shadow(0 0 5px #23d997);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .projects-section {
    .projects {
      margin: 0 2rem;

      .project {
        width: 100%;
        padding: 2rem;
      }
    }
  }
}
