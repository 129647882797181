.footer {
  width: 100%;
  min-height: 20vh;
  padding: 2rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    line-height: 4rem;
    opacity: 0.6;
  }
}
