nav {
  height: 10vh;
  background-color: #1b1b1b;
  padding: 0 2rem;

  .navbar {
    padding: 0 14%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      font-size: 3.5rem;
      margin-right: 10rem;
      font-family: "Shalimar", cursive;
      color: #23d997;
    }

    .links {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      width: 30rem;

      h3 {
        color: white;
        opacity: 0.7;
        transition: transform 0.2s ease;

        &:hover {
          color: #23d997;
          opacity: 1;
          transform: translateY(-2px);
        }
      }

      .active {
        color: #23d997;
      }
    }

    .social {
      display: flex;
      align-items: center;

      a {
        margin-left: 2rem;

        img {
          width: 3rem;
          transition: transform 0.2s ease;

          &:hover {
            transform: translateY(-2px) scale(1.1);
          }
        }
      }

      .menu {
        width: 4rem;
        margin-left: 3rem;
        cursor: pointer;
        display: none;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(0.9);
        }
      }

      .hidden {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  nav {
    .menu-overlay {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.95;
      z-index: 100;

      .close-menu-overlay {
        font-size: 4rem;
        color: white;
        margin: 2rem 3rem;
        text-align: right;
        cursor: pointer;
        font-family: Verdana, Geneva, Tahoma, sans-serif;

        &:hover {
          color: #23d997;
        }
      }

      .logo {
        margin-right: 0;
      }

      .links {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3 {
          font-size: 3rem;
          color: white;
          margin: 2rem 0;

          &:hover {
            color: #23d997;
          }
        }
      }
    }

    .navbar {
      padding: 0;

      .logo {
        margin-right: 2rem;
      }

      .links {
        display: none;
      }

      .social {
        .menu {
          display: block;
        }
      }
    }
  }
}
