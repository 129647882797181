.contact-section {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .heading {
    margin-bottom: 5rem;
    font-size: 3.5rem;
  }

  form {
    width: 70rem;
    z-index: 10;

    input,
    textarea {
      padding: 0.5rem 1rem;
      border: none;
      outline: none;
      border-radius: 8px;
    }

    input {
      display: block;
      width: 100%;
      margin: 1rem 0;
      font-size: 2.2rem;
    }
    textarea {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-section form {
    width: 90%;
  }
}
