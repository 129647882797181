@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&family=Poppins:wght@300;400;500;600&family=Shalimar&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  background-color: #1b1b1b;
  color: white;
  font-family: "Josefin Sans", sans-serif;
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px #23d997;
  padding: 1rem 3rem;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  transition: all 0.3s ease;
  animation: btn 1s ease;

  &:hover {
    border-radius: 0;
    background-color: #23d997;
    box-shadow: none;
    color: #1b1b1b;
  }
}

@keyframes btn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.skew {
  display: inline-block;
  background-color: #23d997;
  transform: skewX(-15deg);
  padding: 1rem 2rem;

  .unskew {
    display: inline-block;
    transform: skewX(15deg);
    color: #1b1b1b;
  }
}

.hidden {
  display: none;
}

svg {
  position: fixed;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  svg {
    top: 40vh;
    height: 30vh;
  }
}

@import "./styles/navbar";
@import "./styles/about";
@import "./styles/service";
@import "./styles/skill";
@import "./styles/footer";
@import "./styles/projects";
@import "./styles/blogs";
@import "./styles/contact";
