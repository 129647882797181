.blogs-section {
  width: 100%;
  min-height: 90vh;
  padding: 5rem 2rem;

  h1 {
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 5rem;
  }

  .loading {
    width: 100%;
    display: grid;
    place-items: center;
    img {
      width: 25rem;
    }
  }

  .blogs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .blog {
      margin: 3rem;
      width: 60rem;

      img {
        width: 100%;
      }

      .blog-title {
        margin: 2rem 0;
        font-size: 2.5rem;
      }

      .blog-brief {
        margin-bottom: 2rem;
        font-size: 1.6rem;
        opacity: 0.6;
      }

      a {
        font-size: 1.6rem;
        color: white;
        border: 1px solid #23d997;
        text-decoration: none;
        padding: 1rem 2rem;
        transition: all 0.3s ease;

        &:hover {
          background-color: #23d997;
          color: black;
        }
      }
    }
  }
}
