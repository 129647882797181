.services {
  min-height: 100vh;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  h1 {
    font-size: 5rem;
  }

  .design,
  .develop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20rem;
      filter: drop-shadow(5px 5px 10px #23d997);
    }

    .details {
      width: 60%;
      h2 {
        font-size: 4rem;
        margin-bottom: 3rem;
      }

      p {
        font-size: 2.2rem;
        line-height: 150%;
        opacity: 0.6;
      }
    }
  }

  .design img {
    margin-right: 3rem;
  }

  .develop {
    flex-direction: row-reverse;

    img {
      margin-left: 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .services {
    padding: 5rem 2rem;

    h1 {
      font-size: 3.5rem;
      margin: 0 2rem 5rem;
      text-align: center;
    }

    .design,
    .develop {
      background-color: #1b1b1b;
      z-index: 10;
      flex-direction: column;
      border: 1px solid none;
      border-radius: 10px;
      box-shadow: 0px 0px 20px 0px #23d997;
      margin: 2rem 0;
      padding: 3rem 2rem;

      .details {
        width: 100%;
        margin-top: 5rem;
        text-align: center;

        h2 {
          font-size: 3rem;
        }
      }
    }

    .design img {
      margin-right: 0rem;
    }

    .develop img {
      margin-left: 0rem;
    }
  }
}
